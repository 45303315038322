import React, { FC, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { getUserToken, refreshToken, removeUser, tokenToUser, updateUserToken } from "../../services/userService";
import { useAuth } from "../../contexts/auth2";
import OrderService from "../../services/orderService";
import OrderUpdateModel from "../../models/orderUpdate.model";
import { StatusEnum } from "../../models/status.enum";
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { LogoLayout } from "../../layouts/base/logo.layout";
import { MessageDialog, PropsMessageDialog } from "../../components/message-dialog";
import { handlePromise } from "../../utils/handlePromisse";
import Space from "../../components/Space";

const MainContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MercadoPagoPage: FC = () => {
  const navigate = useNavigate()
  const { setUser } = useAuth()
  const [dialogMessage, setDialogMessage] = useState<PropsMessageDialog>({
    open: false,
    message: "",
    onConfirm: () => {},
    confirmButtonText: "Fechar"
  })

  const showDialog = (title: string, message: string, onConfirm: () => void = () => navigate('/dashboard/app')) => {
    setDialogMessage({
      open: true,
      title: title,
      message: message,
      onConfirm: onConfirm,
      confirmButtonText: "Fechar"
    })
  }

  const updateToken = async () => {
    const [result, error] = await handlePromise(refreshToken(getUserToken() ?? ''))
    if (error) {
      showDialog("Erro inesperado", "Ocorreu um erro inesperado")
      return;
    }

    removeUser()
    updateUserToken(result.data.newToken)
    setUser(tokenToUser())
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const paymentId = queryParams.get("payment_id") as string
    let status = queryParams.get("status") as string
    const externalReference = queryParams.get("external_reference") as string ?? ""
    const statusAccepted = [StatusEnum.PENDING, StatusEnum.APPROVED, StatusEnum.IN_PROCESS]
    const isPreapproval = queryParams.get("is_preapproval") as string ?? "false"
    const paymentType = queryParams.get("payment_type") as string ?? ""

    if (status == null) {
      showDialog("Erro inesperado", "Ocorreu um erro inesperado")
      return;
    }

    if (statusAccepted.indexOf(status as StatusEnum) >= 0) {
      let title = "Erro"
      let message = "Não foi possível realizar o pagamento"

      const orderUpdateModel = {
        status: status,
        acquirerId: 1,
        externalReference: externalReference,
        isPreapproval: isPreapproval === 'true',
        paymentType: paymentType,
      } as OrderUpdateModel

      OrderService.update(paymentId, orderUpdateModel).then(response => {
        status = response.data.status
        setTimeout(() => {
          updateToken().then(() => {
            if (status === 'approved') {
              message = 'O pagamento foi aprovado e você já pode utilizar os recursos do plano'
              title = "Parabéns!!! 🎉🎉"
            } else if (status === 'in_process' || status === 'pending') {
              message = 'Após o período de verificação você poderá aproveitar todos os benefícios'
              title = "Aguardando aprovação do pagamento"
            }

            showDialog(title, message)
          }).catch(() => {
            removeUser()
            showDialog(title, "Ocorreu um erro inesperado")
          })
        }, 15000) // 15 seconds delay
      })

    } else {
      showDialog("Erro inesperado", "Ocorreu um erro inesperado")
    }
  }, [])

  return (
    <>
      <MainContent>
        <MessageDialog {...dialogMessage} />
        <LogoLayout/>
        <Typography>Processando pagamento...</Typography>
        <Space pixels={16} direction={'v'}/>
        <CircularProgress/>
      </MainContent>
    </>
  );
}

export default MercadoPagoPage
